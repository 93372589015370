
import { Component, Vue } from "vue-property-decorator";

@Component({
  components: {
    News: () => import("@/app/modules/news/components/NewsComponent.vue")
  }
})
export default class NewsPage extends Vue {
  get newsData() {
    return this.$store.getters["news/data"];
  }

  created() {
    if (this.$route.params?.from != "push") {
      this.$store.dispatch("news/fetch").then(({ data }) => {
        this.$store.commit("news/setData", data.data);
      });
    }
  }
}
